.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #131313;
  color: #c7c7c7;
  height: 55px;
}

.achievementsIcon {
  margin-right: 5px;
}

.changeLogIcon {
  margin-right: 6px;
}

.navIcon:hover {
  transition: 0.2s;
  color: #ffffff;
}

.navIcon:hover,
.navInventory:hover {
  cursor: pointer;
}

.navInventory {
  padding: 5px 60px 5px 60px;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
}

.navInventory:hover {
  transition: 0.2s;
  border: 2px solid #ffffff;
}

.navLeft {
  margin-left: 25px;
  width: 150px;
  display: flex;
  justify-content: left;
}

.navRight {
  margin-right: 25px;
  width: 150px;
  display: flex;
  justify-content: right;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}