.locationNavContainer {
  background-color: rgb(13, 13, 24);
  height: calc(100vh - 60px);
  color: #fff;
  padding-top: 5px;
}

.region {
  display: flex;
  justify-content: center;
}

.region:hover {
  cursor: pointer;
}

.regionText {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.regionText:hover {
  transition: 0.2s;
}

.wolfal-active,
#wolfal:hover {
  background-color: #41619c;
}

.candem-active,
#candem:hover {
  background-color: #31642f;
}

.culper-active,
#culper:hover {
  background-color: #5c2e0f;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
