.mainContent {
  min-height: calc(100vh - 55px);
  background-color: rgb(101, 101, 148);
  display: flex;
}

.box {
  height: 20px;
  width: 20px;
  background-color: blueviolet;
}
