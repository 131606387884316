.mainLoading {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  position: absolute;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}
