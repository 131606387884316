.activeRegionContent {
  margin-left: 20px;
}

.activitySelection {
  display: flex;
}

.activityContainer {
  height: 165px;
  width: 250px;
  margin-right: 50px;
  background-color: cadetblue;
  border-top: 4px solid red;
  border-radius: 5px;
}