.settings {
  width: 100vw;
  position: absolute;
  z-index: 10;
  background-color: rgb(204, 112, 69);
  box-shadow: 0 0px 15px 0px #00000066;
}

.settingsNav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.xIcon {
  margin-top: 7px;
  padding: 5px 40px 5px 40px;
  border: 2px solid #c7c7c7;
  color: #ffffff;
  border-radius: 5px;
}

.xIcon:hover {
  cursor: pointer;
  transition: 0.2s;
  border: 2px solid #ffffff;
}
