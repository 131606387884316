.regionContainer {
  margin-left: 20px;
  height: fit-content;
  display: flex;
  align-items: baseline;
}

.subregions {
  display: flex;
}

.subregion {
  margin-left: 25px;
}

.subregion:hover {
  cursor: pointer;
  text-decoration: underline;
}

.active-subregion {
  font-weight: 600;
}
